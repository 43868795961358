import * as React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import "./textSection.css"

const Title = ({ className, title, subtitle, headingTag, icon, temp }) => {

  const HeadingTag = headingTag || 'h2';

  return (
    <div className="title-container">
        {subtitle &&
          <span className="section-subtitle">{subtitle}</span>
        }
        <HeadingTag className={`section-title ${className}`}>
          {temp &&
            <div className="before-title-image-container">
              <GatsbyImage
                image={icon.childImageSharp.gatsbyImageData}
                alt=""
              />
            </div>
          }
          <span>{title}</span>
          {icon &&
            <div className="image-container">
              <GatsbyImage
                image={icon.childImageSharp.gatsbyImageData}
                alt=""
              />
            </div>
          }
        </HeadingTag>
    </div>
  )
}

Title.defaultProps = {
  headingTag: `h2`,
  temp: false,
}

Title.propTypes = {
  headingTag: PropTypes.string,
  temp: Boolean
}

export default Title